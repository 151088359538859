<template>
  <main class="round-post-page">
    <section>
      <div class="container py-5">
        <div class="row">
          <div class="md-down:col-12 lg:col-5 xl:col-3 mb-3">
            <div style="top: 85px">
              <mega-card class="card-round">
                <mega-drop-zone
                  class="rounded-0"
                  @change="renderFile"
                  :type="['video/mp4']"
                >
                  <mega-video ratio="16x9" v-if="!preview && !loadingVideo" />
                  <mega-loading v-if="loadingVideo" />
                  <div class="video-container" v-show="preview">
                    <video
                      id="video"
                      @mouseenter="video_toggle"
                      @mouseleave="video_stop"
                      ref="video"
                      alt=""
                      style="
                        transition: 0.1s;
                        height: inherit;
                        position: absolute;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                      "
                      :src="preview"
                      v-show="preview"
                      :class="{ 'opacity-0': rendering }"
                    />
                  </div>
                </mega-drop-zone>
              </mega-card>

              <mega-progress
                :value="(progress.loaded / progress.total) * 100"
              />

              <!-- <mega-card class="card-round">
                <div class="card-body">
                  <mega-switch
                    class="w-100 mb-0 bg-success"
                    :label="$t('visible_exp')"
                    @click="
                      round['visible_in_explore'] = !round['visible_in_explore']
                    "
                    :checked="round['visible_in_explore']"
                  />
                </div>
              </mega-card> -->
              <mega-switch
                class="w-100 mt-3 bg-success"
                v-model="toTop"
                :label="$t('round_to_top')"
              ></mega-switch>
              <to-top v-show="toTop" ref="toTop" :round="idt_round"> </to-top>
              <div class="w-100" style="text-align: center">
                <mega-button
                  class="btn-success"
                  :loading="loading"
                  :disabled="!valid"
                  @click="submit"
                  >{{ $t("make_video") }}</mega-button
                >
              </div>
            </div>
          </div>

          <div class="md-down:col-12 lg:col-7 xl:col-9">
            <mega-card :title="$t('video_opt')">
              <div class="card-body">
                <mega-select
                  :placeholder="$t('sel_user')"
                  :label="$t('sel_user')"
                  filter
                  @search="search"
                  :options="users"
                  v-model="round.idt_user"
                  style="z-index: 200"
                />

                <mega-input
                  :label="$t('title')"
                  v-model="round.title"
                  :max="30"
                />

                <mega-textarea
                  :label="$t('desc')"
                  v-model="round.message"
                  rows="3"
                  :max="300"
                />

                <mega-select
                  label="Выбор категории"
                  :options="categories"
                  v-model="round.category"
                />
              </div>
            </mega-card>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import ToTop from "../Round_Post/components/to-top";
import MP4Box from "mp4box";

export default {
  name: "RoundPostVideoPage",
  props: ["roundId"],
  data() {
    return {
      idt_round: "",
      toTop: false,
      users: [],
      models: [],
      categories: [],
      loading: false,
      rendering: false,
      playVideo: false,
      video: undefined,
      preview: undefined,
      loadingVideo: false,
      acceptableCodec: undefined,
      progress: {},
      filter: {
        type: undefined,
        group: undefined
      },
      sliderOption: {
        spaceBetween: 30,
        slidesPerView: "auto"
      },
      round: {
        lat: 59.9598743,
        lng: 30.2965115,
        height: 100,
        ref_plane_height: 0,
        scale: 1,
        title: "",
        message: "",
        idt_user: undefined,
        visible_in_explore: true,
        idc_round_type: 11,
        idt_model_modif: undefined,
        preview: undefined,
        video_preview: undefined,
        is_location_hidden: true,
        place_name: "",
        place_address: "",
        empty: "",
        category: "",
        profile: {}
      }
    };
  },
  computed: {
    video_link() {
      return this.round.media
        ? this.round.media["url"]
        : this.round.videos
        ? this.round.videos[0]["url"]
        : null;
    },
    valid() {
      let required = ["idc_round_type", "idt_user", "preview"];
      let valid = true;

      Object.keys(this.round).map(prop => {
        if (required.includes(prop) && this.round[prop] === undefined) {
          valid = false;
        }
      });

      return valid;
    }
  },
  methods: {
    search(search) {
      let params = {};

      if (search) params["search"] = search;

      this.$api.v1.get("/userList", { params }).then(response => {
        this.users = [];

        response.data.users.map(user => {
          this.users.push({
            value: user.idt_user,
            name: user.name + (user.username ? " (" + user.username + ")" : "")
          });
        });
      });
    },

    submit() {
      this.loading = true;

      let data = this.round;
      let formData = new FormData();

      delete data.profile;
      data["height"] = data["height"] * 0.01;
      data.sn_id = this.$store.getters.selectedSnId;

      Object.keys(data).map(prop => {
        if (this.round[prop] !== undefined)
          formData.append(prop, this.round[prop]);
      });

      this.$api.v2
        .post("/round", formData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: this.onProgress
        })
        .then(response => {
          this.idt_round = response.data.round.idt_round;

          // Timeout is needed so that the "round" prop updates in "toTop"
          setTimeout(() => {
            if (this.toTop) {
              this.$refs.toTop.submit();
            } else {
              this.$router.push("/round/" + this.idt_round);
            }
            this.loading = false;
          }, 0);
        })
        .catch(err => {
          console.log(err.response);
          if (err.response.data.code === 15) {
            this.$alert.danger(
              "Неподдерживаемый формат. Пожалуйста, используйте mp4 H.264"
            );
            this.$refs.video.pause();
            this.$refs.video.removeAttribute("src");
            this.$refs.video.load();
          } else {
            this.$alert.danger("Oops.. Server error");
          }
          this.loading = false;
        });
    },

    onProgress(event) {
      this.progress = event;
    },

    video_toggle() {
      this.$refs.video.play();
    },

    video_stop() {
      this.$refs["video"].pause();
    },

    updatePosition(location) {
      this.round.place_address = location.address;
      this.round.place_name = location.name;
      this.round.lat = location.lat;
      this.round.lng = location.lng;
    },

    getCodec() {
      let mp4boxfile = MP4Box.createFile();
      mp4boxfile.onError = function(e) {
        console.log(e);
      };

      mp4boxfile.onReady = info => {
        let result = false;
        for (const track in info.tracks) {
          console.log(`Codec: ${info.tracks[track].codec}`);
          if (info.tracks[track].codec.includes("mp4a")) {
            result = true;
          }
        }
        this.acceptableCodec = result;
      };

      const range = "bytes=0-50000";
      fetch(this.$refs.video.src, { headers: { range } })
        .then(res => res.arrayBuffer())
        .then(function(arrayBuffer) {
          arrayBuffer.fileStart = 0;
          mp4boxfile.appendBuffer(arrayBuffer);
        });
    },

    renderFile(file) {
      this.video = file;
      this.round.video_preview = file;

      this.rendering = true;

      let reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onprogress = () => {
        this.loadingVideo = true;
      };

      reader.onloadend = () => {
        this.preview = URL.createObjectURL(new Blob([reader.result])); // Not being revoked anywhere but whatever
        this.loadingVideo = false;
        this.rendering = false;

        let video = document.getElementById("video");
        video.onloadeddata = () => {
          this.getCodec();

          //make snapshot for preview
          let canvas = document.createElement("canvas");
          canvas.width = this.$refs.video.videoWidth;
          canvas.height = this.$refs.video.videoHeight;
          console.log(canvas.width, canvas.height);
          let ctx = canvas.getContext("2d");

          video.onseeked = () => {
            //e.target.removeEventListener(e.type, arguments.callee); // remove the handler or else it will draw another frame on the same canvas, when the next seek happens
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            let dataURI = canvas.toDataURL("image/jpeg");

            //convert uri to file
            fetch(dataURI)
              .then(res => res.arrayBuffer())
              .then(buf => {
                this.round.preview = new File([buf], "cat.jpeg", {
                  type: "image/jpeg"
                });
              });
          };
          video.currentTime = Math.min(3, video.duration);
        };
      };
    }
  },
  mounted() {
    this.$navbar.name = this.$t("make_video");

    this.search();

    // get availible categories
    // ВРЕМЕННО КАТЕГОРИИ СТРИМОВ
    this.$api.v2base.get("/streams/categories").then(res => {
      res.data.forEach(category => {
        this.categories.push({
          value: category.id,
          name: category.label_ru
        });
      });
    });
  },
  components: {
    ToTop
  }
};
</script>

<style lang="sass" scoped>
.video-container
  height: 100%
  padding-top: 56.25%
  position: relative
</style>
