var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "round-post-page" }, [
    _c("section", [
      _c("div", { staticClass: "container py-5" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "md-down:col-12 lg:col-5 xl:col-3 mb-3" }, [
            _c(
              "div",
              { staticStyle: { top: "85px" } },
              [
                _c(
                  "mega-card",
                  { staticClass: "card-round" },
                  [
                    _c(
                      "mega-drop-zone",
                      {
                        staticClass: "rounded-0",
                        attrs: { type: ["video/mp4"] },
                        on: { change: _vm.renderFile }
                      },
                      [
                        !_vm.preview && !_vm.loadingVideo
                          ? _c("mega-video", { attrs: { ratio: "16x9" } })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.loadingVideo ? _c("mega-loading") : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.preview,
                                expression: "preview"
                              }
                            ],
                            staticClass: "video-container"
                          },
                          [
                            _c("video", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.preview,
                                  expression: "preview"
                                }
                              ],
                              ref: "video",
                              class: { "opacity-0": _vm.rendering },
                              staticStyle: {
                                transition: "0.1s",
                                height: "100%",
                                position: "absolute",
                                top: "0",
                                left: "0",
                                "object-fit": "cover",
                                width: "100%"
                              },
                              attrs: { id: "video", alt: "", src: _vm.preview },
                              on: {
                                mouseenter: _vm.video_toggle,
                                mouseleave: _vm.video_stop
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("mega-progress", {
                  attrs: {
                    value: (_vm.progress.loaded / _vm.progress.total) * 100
                  }
                }),
                _vm._v(" "),
                _c("mega-switch", {
                  staticClass: "w-100 mt-3 bg-success",
                  attrs: { label: _vm.$t("round_to_top") },
                  model: {
                    value: _vm.toTop,
                    callback: function($$v) {
                      _vm.toTop = $$v
                    },
                    expression: "toTop"
                  }
                }),
                _vm._v(" "),
                _c("to-top", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.toTop,
                      expression: "toTop"
                    }
                  ],
                  ref: "toTop",
                  attrs: { round: _vm.idt_round }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "w-100",
                    staticStyle: { "text-align": "center" }
                  },
                  [
                    _c(
                      "mega-button",
                      {
                        staticClass: "btn-success",
                        attrs: { loading: _vm.loading, disabled: !_vm.valid },
                        on: { click: _vm.submit }
                      },
                      [_vm._v(_vm._s(_vm.$t("make_video")))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "md-down:col-12 lg:col-7 xl:col-9" },
            [
              _c("mega-card", { attrs: { title: _vm.$t("video_opt") } }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("mega-select", {
                      staticStyle: { "z-index": "200" },
                      attrs: {
                        placeholder: _vm.$t("sel_user"),
                        label: _vm.$t("sel_user"),
                        filter: "",
                        options: _vm.users
                      },
                      on: { search: _vm.search },
                      model: {
                        value: _vm.round.idt_user,
                        callback: function($$v) {
                          _vm.$set(_vm.round, "idt_user", $$v)
                        },
                        expression: "round.idt_user"
                      }
                    }),
                    _vm._v(" "),
                    _c("mega-input", {
                      attrs: { label: _vm.$t("title"), max: 30 },
                      model: {
                        value: _vm.round.title,
                        callback: function($$v) {
                          _vm.$set(_vm.round, "title", $$v)
                        },
                        expression: "round.title"
                      }
                    }),
                    _vm._v(" "),
                    _c("mega-textarea", {
                      attrs: { label: _vm.$t("desc"), rows: "3", max: 300 },
                      model: {
                        value: _vm.round.message,
                        callback: function($$v) {
                          _vm.$set(_vm.round, "message", $$v)
                        },
                        expression: "round.message"
                      }
                    }),
                    _vm._v(" "),
                    _c("mega-select", {
                      attrs: {
                        label: "Выбор категории",
                        options: _vm.categories
                      },
                      model: {
                        value: _vm.round.category,
                        callback: function($$v) {
                          _vm.$set(_vm.round, "category", $$v)
                        },
                        expression: "round.category"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }